import React, { Component } from 'react';

import './Header.css';

class Header extends Component {

    render() {
        return null;
        // return (    
        //     // <div className="row color animated bounceInLeft">
        //     <div className="row color">
        //         <div className="col-md-3"></div>
        //         <div className="col-md-6 col-xs-12 mt-3 mb-3 ml-0">
        //             {/* <img src="http://surveys.cademonline.cl/upload/templates/T_CADEMONLINE/images/logo_cademonline.png" alt="Logo CademOnline"></img> */}
        //             {/* <img src="img/logo_cademonline.png" alt="Logo CademOnline"></img> */}
        //         </div>
        //         <div className="col-md-3"></div>
        //     </div>
        // );
    }
}

export default Header;