//Producción
// export const API_BASE_URL = "https://api-cadem-comunidad-dot-comunidad-cadem.appspot.com"; // Produccion
// export const API_BASE_URL_CADEM_ONLINE = "https://api-cadem-online-dot-cademonline.ue.r.appspot.com"; // Produccion
//Producción
// export const API_BASE_URL = "https://backend-form-comunidad-cadem-pyxwmvpacq-ue.a.run.app"; // Produccion Cloud Run
// export const API_BASE_URL_CADEM_ONLINE = "https://backend-formulario-cademonline-ba7zkvym3a-ue.a.run.app"; // Produccion Cloud Run

export const API_BASE_URL  = process.env.NODE_ENV === 'production'
    ? 'https://backend-form-comunidad-cadem-pyxwmvpacq-ue.a.run.app' // Produccion Cloud Run                            
    : 'http://localhost:5000'; // Desarrollo
export const API_BASE_URL_CADEM_ONLINE  = process.env.NODE_ENV === 'production'
    ? 'https://backend-formulario-cademonline-ba7zkvym3a-ue.a.run.app' // Produccion Cloud Run         
    : 'http://localhost:5001'; // Desarrollo