import React, { Component } from 'react';
import { QueryParamProvider } from 'use-query-params';
import './App.css';
import formulario from './components/forms/formulario';
import formulario_test from './components/forms/formulario-test';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class App extends Component {

    render() {

    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>                
                <Route path="/formulario" component={formulario} />
                <Route path="/formulario-test" component={formulario_test} />
            </QueryParamProvider>
        </Router>

        );
    }
}

export default App;
