import React, {useEffect,useState, Fragment } from 'react';
import { browserName, isBrowser, isMobile, CustomView, deviceDetect } from 'react-device-detect';

import { useQueryParam, NumberParam, StringParam,ArrayParam } from 'use-query-params';
// import { Row, Col, Input } from 'reactstrap';
import { Col } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import Header from '../Header/Header';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

///////////////////////////////////////////////////////////////////////////////
// React Hook Form
import { useForm } from "react-hook-form";
///////////////////////////////////////////////////////////////////////////////

// Import CSS
import './formulario.css';
import { red } from '@material-ui/core/colors';

// Helpers
import Helpers from '../../helpers/index'; 
// import * as helpers from '../../helpers/helpers.js'; 
// const helpers = require('../../helpers/helpers')

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";
///////////////////////////////////////////////////////////////////////////////
//Validar Rut
const { validate, clean, format } = require('rut.js')
const helpers = new Helpers();

// Configuraciónes
const config = require('../../configuration/config')
const API_BASE_URL = config.API_BASE_URL;
const API_BASE_URL_CADEM_ONLINE = config.API_BASE_URL_CADEM_ONLINE;


// const url_base = 'https://apidev.cadem.cl';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const styles = theme => ({
    icon: {
      color: "red !important"
    },
  });



function getSteps() {
//   return ['Datos Personales', 'Actividad', 'Finalizar'];
// return ['Datos Personales', 'Actividad'];
// return ['Datos Personales'];
    return ['DATOS PERSONALES'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return '';
    case 1:
      return '';
    case 2:
      return '';
    default:
      return '';
  }
}

const FormularioComunidadCadem = () => {

const recaptchaRef = React.useRef();
 ///////////////////////////////////////////////////////////////////////////////

const [blocking, setBlocking] = useState(false);
const [token, setToken] = useQueryParam('token', StringParam);
const [tokenValid, setTokenValid] = useState(null);

const [stateNombresMensaje, setStateNombresMensaje] = useState(false);
const [stateApellidosMensaje, setStateApellidosMensaje] = useState(false);
const [stateRutMensaje, setStateRutMensaje] = useState(false);
const [stateRutMensajeV, setStateRutMensajeV] = useState(false);
const [stateRutMensajeR, setStateRutMensajeR] = useState({
    estado:false,
    mensaje:""
});


const [stateNombresValidation, setStateNombresValidation] = useState(false);
const [stateApellidosValidation, setStateApellidosValidation] = useState(false);
const [stateRutValidation, setStateRutValidation] = useState(false);
const [stateRutExiste, setStateRutExiste] = useState(false);
const [stateAnoValidation, setStateAnoValidation] = useState(false);
const [stateMesValidation, setStateMesValidation] = useState(false);
const [stateDiaValidation, setStateDiaValidation] = useState(false);



const [MESES, setMESES] = useState([    
    { value: 1, label: 'Enero' }, 
    { value: 2, label: 'Febrero' }, 
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' }
]);


const [stateAnoMensaje, setStateAnoMensaje] = useState(false);
const [stateMesMensaje, setStateMesMensaje] = useState(false);
const [stateDiaMensaje, setStateDiaMensaje] = useState(false);

const [stateRegionMensaje, setStateRegionMensaje] = useState(false);
const [stateComunaMensaje, setStateComunaMensaje] = useState(false);

const [stateExtraData, setStateExtraData] = useState({});
const [stateForm, setStateForm] = React.useState({
    // id:"",
    // username:"",
    nombres:"",
    apellidos:"",
    rut:"",
    dv:"",
    fecha_nacimiento:"",
    ano:"Seleccionar ...",
    mes:"Seleccionar ...",
    mes_label: "Seleccionar ...",
    dia:"Seleccionar ...",
    cod_ocupacion:"",
    ocupacion:"",
    cod_niveleducacional:"",
    niveleducacional:"",
    facebook:"",
    instagram:"",
    twitter:"",
    comunidad_cadem_id:""

    });
///////////////////////////////////////////////////////////////////////////////
const { handleSubmit, register, setValue, errors } = useForm();



const onSubmit = async (valuesForm) => {
    setBlocking(true);
    
    console.log('stateRutExiste 1111', stateRutExiste);
    let resultValidacionDatosPersonales = validacionDatosPersonales(valuesForm);
    let validacionRut = await validateRutIngresado();    
    // console.log('validacionRut', validacionRut);
    

    if (resultValidacionDatosPersonales && validacionRut) {   
        console.log('saveForm');
        console.log('saveForm validacionRut', validacionRut);        
        saveForm();
        // if(activeStep===0){handleNext()};
        // if(activeStep===1){saveForm()};               
    } else{
        console.log('Errors en saveFOrm'); 
        console.log('Errors validacionRut', validacionRut);       
        setBlocking(false);
    }          
}


const validacionDatosPersonales = (valuesForm) =>{
    if(stateForm.nombres.trim() == "" || stateForm.nombres.length < 2){   
        setStateNombresMensaje(true);
      }else{
        setStateNombresMensaje(false);
      }

     if(stateForm.apellidos.trim() == "" || stateForm.nombres.length < 2){      
        setStateApellidosMensaje(true);
      }else{
        setStateApellidosMensaje(false);
      }

      if(stateForm.rut == ""){      
        setStateRutMensaje(true);
      }else{  
            setStateRutMensaje(false);  

            if(!validate(stateForm.rut) || stateForm.rut.length <=6){                
                setStateRutMensajeV(true);
            }else{
                setStateRutMensajeV(false);
            }
      }

      if(stateForm.ano == "Seleccionar ..."){      
        setStateAnoMensaje(true);
      }else{
        setStateAnoMensaje(false);
      }

      if(stateForm.mes == "Seleccionar ..."){      
        setStateMesMensaje(true);
      }else{
        setStateMesMensaje(false);
      }

      if(stateForm.dia == "Seleccionar ..."){      
        setStateDiaMensaje(true);
      }else{
        setStateDiaMensaje(false);
      }               

    if(stateForm.nombres.trim() == "" || stateForm.nombres.length < 2
    || stateForm.apellidos.trim() == "" || stateForm.apellidos.length < 2
    || stateForm.rut == ""  
    || stateRutMensajeR.estado
    || !validate(stateForm.rut) || stateForm.rut.length <=6
    || stateForm.ano == "Seleccionar ..." || stateForm.mes == "Seleccionar ..." || stateForm.dia == "Seleccionar ..."
    ){
        
        return false;
    }else{        
        return true;
    }

   

}


///////////////////////////////////////////////////////////////////////////////
const [OCUPACIONES, setOcupaciones] =  React.useState([]);
const [NIVELEDUCACIONAL, setNivelEducacional] =  React.useState([]);


const [comunas, setComunas] =  React.useState([]);
const [comuna_id, setComuna_id] =  React.useState('');
const [region_id,setRegion_id] =React.useState('0');
const [telefono_fijo,setTelefono_fijo,] =React.useState('');

const [years,setYears] =React.useState([]);
const [days,setDays] =React.useState([]);

const [celular,setCelular] =React.useState('');
const [dia,setDia] =React.useState('');
const [fecha_nacimiento, setFecha_nacimiento] =React.useState('');
const [sostenedor,setSostenedor] =React.useState('');
const [email,setEmail] =React.useState('');
const [sid,setSid] =React.useState('');
const [id_lime,setId_lime] =React.useState('');
const [error,setError] =React.useState([]);
 

const classes = useStyles();
const [activeStep, setActiveStep] = React.useState(0);
const steps = getSteps();

  useEffect(() => {

    // console.log('token', token);
    // console.log('tokenValid', tokenValid);
    if(token){
        // console.log('token existe', token);
        validateToken();                   
        cargaYear();
        loadOcupaciones();
        loadNivelEducacional();
        // console.log('browserName', browserName);
        // console.log('isBrowser', isBrowser);
        // console.log('isMobile', isMobile);
        
        // console.log('deviceDetect()', deviceDetect());

    }else{
        // console.log('token no existe', token);
        setTokenValid(false);
    }
        


},[]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


// Servicios Eventos
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const OnblurValidateRut = async() =>{
    let datosformulario = {... stateForm};    
    let rutFormatiado = stateForm.rut.length > 1 ? helpers.formateaRut(stateForm.rut) : "";                    
    datosformulario.rut = rutFormatiado
    setStateForm(datosformulario);
}
const validateRutIngresado = async() =>{
        
    if(validate(stateForm.rut) && stateForm.rut.length > 6){
        let rut = helpers.getRutSinDV(stateForm.rut);  
        let dv = helpers.getDV(stateForm.rut);           
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Validar Rut Comunidad Cadem
        let validation = false;
        await axios({
            url: API_BASE_URL + '/services-validate-rut',
            method: 'POST',
            data: {            
            rut:rut,
            dv:dv
            }
        },
        )
        .then(async (response) => {  
            if(response.data.ok){
                ///////////////////////////////////////////////////////////////////////////////////////////
                // Validar en Cadem Online
                ///////////////////////////////////////////////////////////////////////////////////////////
                await axios({
                    url: API_BASE_URL_CADEM_ONLINE + '/services-validate-rut',
                    method: 'POST',
                    data: {            
                    rut:rut,
                    dv:dv
                    }
                },
                ).then(async (response) => {          
                    if(response.data.ok){
                        validation = true;
                        setStateRutMensajeR({estado:false,mensaje:""})
                        setStateRutExiste(true);
                        return true;
                    }   
                })
                .catch(error => { 
                        validation = false; 
                        setStateRutExiste(false);
                        setStateRutMensajeR({
                            estado: true,
                            mensaje: error.response.data.mensaje
                        })   
                        return false;         
                });
                ///////////////////////////////////////////////////////////////////////////////////////////
            }      
        })
        .catch(error => {
            validation = false; 
            setStateRutExiste(false);                                                             
            setStateRutMensajeR({
                estado: true,
                mensaje: error.response.data.mensaje
            })
            
        })
        
        return validation;
       
    }else{        
        setStateRutMensajeR({estado:false,mensaje:""})
        setStateRutExiste(false);
        return false;
    }
}


const validateToken = async() =>{

    axios({
        url: API_BASE_URL + '/services-validate-token',
        method: 'POST',
        data: {
          token: token
        }
        // responseType: 'blob',
        // headers: {
        //   'Access-Control-Allow-Origin': '*',
        //   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        //   'Authorization': token 
        // }
      },
      ).then((response) => {
        // console.log('response.data', response.data);
        if(response.data.ok){
            setTokenValid(true);
            setStateExtraData(response.data.extradata)
        }else{
            setTokenValid(false);
        }                        
      })
      .catch(error => {                                                  
            // console.log('response.data', error.response.data);
            setTokenValid(false);
    });
}

const saveForm = async () => {
    
    const tokenRecaptcha = await recaptchaRef.current.executeAsync();
    // console.log('tokenRecaptcha', tokenRecaptcha);

    let datosformulario = {... stateForm};
    let rut = helpers.getRutSinDV(datosformulario.rut); 
    let dv = helpers.getDV(datosformulario.rut); 
    datosformulario.rut = rut 
    datosformulario.dv = dv;
            
    datosformulario.fecha_nacimiento = `${datosformulario.dia}/${datosformulario.mes}/${datosformulario.ano}`;
    datosformulario.cod_ocupacion = datosformulario.cod_ocupacion == "Seleccionar ..." ? "" : datosformulario.cod_ocupacion;
    datosformulario.cod_niveleducacional = datosformulario.cod_niveleducacional == "Seleccionar ..." ? "" : datosformulario.cod_niveleducacional;    
    datosformulario.comunidad_cadem_id = token;
  
    let infoDevice = deviceDetect();
    datosformulario.browserName = browserName;
    datosformulario.isMobile = isMobile; 
    datosformulario.browserFullVersion = infoDevice.browserFullVersion;
    datosformulario.browserMajorVersion = infoDevice.browserMajorVersion;
    datosformulario.engineName = infoDevice.engineName;
    datosformulario.engineVersion = infoDevice.engineVersion;
    datosformulario.osName = infoDevice.osName;
    datosformulario.osVersion = infoDevice.osVersion;
    datosformulario.userAgent = infoDevice.userAgent;  
    datosformulario.tokenRecaptcha = tokenRecaptcha;
    
    delete datosformulario.mes_label;

    axios({
        url: API_BASE_URL + '/services-save-form',
        method: 'POST',
        data: {
            datosformulario: datosformulario,
            extradata:stateExtraData
        }
      },
      ).then((response) => {
        if(response.data.ok){
            handleNext()
            setBlocking(false);
        }                      
      })
      .catch(error => {                                                  
            console.log('response.data form', error.response.data);
            setBlocking(false);
            
    });

}


const loadOcupaciones = async () => {

    axios.get(API_BASE_URL+'/services-ocupaciones')  
         .then(response => { 
            // console.log('response OCUPACIONES', response.data.ocupaciones)
            setOcupaciones(response.data.ocupaciones)

         })
         .catch(error => {
             console.log(error)
         });
}

const loadNivelEducacional = async () => {

    axios.get(API_BASE_URL+'/services-niveleducacion')  
         .then(response => { 
            // console.log('response NIVEL EDUCACIONAL', response.data.educacion)
            setNivelEducacional(response.data.educacion)
         })
         .catch(error => {
             console.log(error)
         });
}


const cargaYear = ()=>{
    // console.log('Cargar Años');    
    axios.get(API_BASE_URL + '/services-getyears').then(response => {  
        
        setYears(response.data.listYears);
        
   });
}

const cargaDaysFromMonth = async (vmes)=>{

    const itemMes = MESES.find(x=>x.value ==vmes);
    if(vmes != "Seleccionar ..." && stateForm.ano != "Seleccionar ..."){
        await axios.get(`${API_BASE_URL}/services-getdaysofmonth/${stateForm.ano}/${vmes}`).then(response => {
            setDays(response.data.listDays); 
            setStateForm({
                ...stateForm,
                mes: itemMes.value,
                mes_label: itemMes.label,
                dia:"Seleccionar ..."
               
            });      
        });
    }
    else{
        setStateForm({
            ...stateForm,
            mes: itemMes.value,
            mes_label: itemMes.label,
            dia:"Seleccionar ..."           
        }); 
    }
}

const cargaDaysFromYear = async (vyear)=>{    
    if(vyear != "Seleccionar ..." && stateForm.mes != "Seleccionar ..."){        
        await axios.get(`${API_BASE_URL}/services-getdaysofmonth/${vyear}/${stateForm.mes}`).then(response => {
            setDays(response.data.listDays);
            setStateForm({
                ...stateForm,
                ano: vyear,
                // mes:"Seleccionar ...",
                // mes_label:"Seleccionar ...", 
                dia:"Seleccionar ..."
            });                             
        });
    }
    else{        
        setDays([]);
        setStateForm({
            ...stateForm,
            ano: vyear,
            mes:"Seleccionar ...",
            mes_label:"Seleccionar ...", 
            dia:"Seleccionar ..."
        }); 
    }
}

const inserInput = (e) => {
    // const viene = e.target.name
    // console.log('viene', e.target.value)
    // console.log('e.target.name', viene)
    setStateRutMensajeR({estado:false,mensaje:""})

    let newStateForm = {...stateForm};
    if(e.target.name === 'nombres'){
        let valor = e.target.value  
        newStateForm.nombres = valor;           
        if(valor.trim()  == "" || valor.length < 2)
        {setStateNombresMensaje(true); setStateNombresValidation(false)}else{ setStateNombresMensaje(false); setStateNombresValidation(true)}
    }
    if(e.target.name === 'apellidos'){        
        let valor = e.target.value  
        newStateForm.apellidos = valor;                    
        if(valor.trim() == "" || valor.length < 2)
        {setStateApellidosMensaje(true); setStateApellidosValidation(false)}else{setStateApellidosMensaje(false);setStateApellidosValidation(true);}
    }

    if(e.target.name === 'rut'){
        newStateForm.rut = e.target.value;         
        if(e.target.value == ""){setStateRutMensaje(true);setStateRutMensajeV(false);}else{setStateRutMensaje(false);setStateRutMensajeV(true);}
        if(!validate(e.target.value) || e.target.value.length <=6){
            setStateRutMensajeV(true); 
            setStateRutValidation(false);           
        }else{
            setStateRutMensajeV(false);
            setStateRutValidation(true);
        }       
    }

    // if(e.target.name === 'facebook'){
    //     newStateForm.facebook = e.target.value; 
    //     console.log('newStateForm.facebook', newStateForm.facebook )            
    //     if(e.target.value == ""){setStateApellidosMensaje(true)}else{setStateApellidosMensaje(false)}
    // }

    // if(e.target.name === 'instagram'){
    //     newStateForm.instagram = e.target.value; 
    //     console.log('newStateForm.facebook', newStateForm.instagram )            
    //     if(e.target.value == ""){setStateApellidosMensaje(true)}else{setStateApellidosMensaje(false)}
    // }

    // if(e.target.name === 'twitter'){
    //     newStateForm.twitter = e.target.value; 
    //     console.log('newStateForm.twitter', newStateForm.twitter )            
    //     if(e.target.value == ""){setStateApellidosMensaje(true)}else{setStateApellidosMensaje(false)}
    // }       
    setStateForm(newStateForm);     
}

const handleChangeSelectYears = (selectedOptionMulti) => {
        
    // let newStateForm = {...stateForm};
    // newStateForm.ano = selectedOptionMulti.value;      
    // setStateForm(newStateForm); 

    if(selectedOptionMulti.value == "Seleccionar ..."){      
        setStateAnoMensaje(true);
        setStateAnoValidation(false);
      }else{
        setStateAnoMensaje(false);
        setStateAnoValidation(true);        
      }
      cargaDaysFromYear(selectedOptionMulti.value);
}

const handleChangeSelectMonths= (selectedOptionMulti) => {    
    if(selectedOptionMulti.value == "Seleccionar ..."){      
        setStateMesMensaje(true);
        setStateMesValidation(false);
        setStateDiaValidation(false); 
      }else{
        setStateMesMensaje(false);
        setStateMesValidation(true);       
    } 
    const itemMes = MESES.find(x=>x.value ==selectedOptionMulti.value);  
    setStateForm({
        ...stateForm,
        mes: itemMes.value,
        mes_label: itemMes.label,
        dia:"Seleccionar ..."
       
    });  
    cargaDaysFromMonth(selectedOptionMulti.value);
   
}

const handleChangeSelectDays= (selectedOptionMulti) => { 
    let newStateForm = {...stateForm};
    newStateForm.dia = selectedOptionMulti.value;
    setStateForm(newStateForm); 

    if(selectedOptionMulti.value == "Seleccionar ..."){      
        setStateDiaMensaje(true);
        setStateDiaValidation(false);
      }else{
        setStateDiaMensaje(false);
        setStateDiaValidation(true);
    }
}

const handleChangeSelectOcupacion= (selectedOptionMulti) => { 
    let newStateForm = {...stateForm};

    // console.log('selectedOptionMulti.value',selectedOptionMulti.value);
    // console.log('selectedOptionMulti.text',selectedOptionMulti.label);
    
    newStateForm.cod_ocupacion = selectedOptionMulti.value;
    newStateForm.ocupacion = selectedOptionMulti.label;
    setStateForm(newStateForm); 

}

const handleChangeSelectNivelEducacional= (selectedOptionMulti) => { 
    let newStateForm = {...stateForm};

    newStateForm.cod_niveleducacional = selectedOptionMulti.value;
    newStateForm.niveleducacional = selectedOptionMulti.label;
    setStateForm(newStateForm); 
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    return (
    <section className="section-container mb-4 mt-4" >
        <BlockUi tag="div" blocking={blocking}>
            <div className="container">
            

            <Header></Header>
                <div className="row">
                    <div className="col-xs-12">&nbsp;</div>
                </div>


        
        {
        tokenValid ? (
                    
        <div className ="row mt-4">
            {/* <div className ="row animated bounceInRight delay-1s"> */}
            <div className="col-xs-6 col-md-6" >

                <div className ="row" style={{paddingLeft:34}}>
                    {/* <div className="col-md-3 col-xs-12">&nbsp;</div> */}
                    <div className="col-md-12 col-xs-12">&nbsp; 
                    {/* className="img-padding"                          */}
                            {/* <img src="img/LogoComunidad.png" className="rounded mx-auto d-block" alt="Logo Cadem Online" style={{width:200, height: 200 }}  /> */}
                            {/* <div> */}
                                {/* <img src="img/LogoComunidad.png" className="img-style" alt="Logo Cadem Online" style={{width:200, height: 200 }}  /> */}
                            {/* </div> */}
                            
                            <img src="img/LogoComunidad.png" className="img-style" alt="Logo Cadem Online" />
                            {/* <img src="img/LogoComunidad.png" className="rounded mx-auto d-block" alt="Logo Cadem Online" /> */}
                            
                    </div>
                    
                </div>
                <div className ="row" style={{ paddingTop:40, paddingLeft:40}}>
                    {/* <div className="col-md-3 col-xs-12">&nbsp;</div> */}
                    <div className="col-md-12 col-xs-12">
                        
                        <span  style={{fontSize: 32, fontWeight: "bold"}}>REGÍSTRATE</span>
                        <p className="registrate-style">
                            {/* Se parte de nuestra Comunidad Cadem <br></br>
                            y participa respondiendo encuestas */}
                            Sé parte de nuestra Comunidad Cadem <br></br>
                            y participa de diversos premios respondiendo encuestas.
                        </p>
                        
                        
                    </div>
                    {/* <div className="col-md-3 col-xs-12">&nbsp;</div> */}
                </div>

                {/* <div className="cardbox">
                    <div className="cardbox-body">
                        <div className="container-img">
                            <img src="img/LogoComunidad.png" alt="Logo Cadem Online" />
                        </div>                             
                    </div>
                </div> */}
            </div>
            <div className="col-xs-6 col-md-6 fondo">                    
                {/* <form action="" name="actualizacion"> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="cardbox">
                        {/* <div className="cardbox-body" style={{paddingTop:25}}> */}
                        <div className="cardbox-body">
                            {/* <legend className="animated bounceIn faster"><h1 className="text-center font-weight-bold">Inscribete 2</h1></legend>
                            <hr />
                            <br></br> */}

                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active: classes.icon
                                        }
                                    }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                                ))}
                            </Stepper>


                            <div style={{paddingTop:20}}>                                    
                                {
                                    activeStep === 0 ? (

                                    <Fragment>                     

                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                {/* <h5>Nombres:</h5> */}
                                                <span className="titles-form">Nombre:</span>
                                                <input type="text" className="form-control" id="nombres" name="nombres" value={stateForm.nombres}
                                                    onChange={inserInput}
                                                    ref={register()}
                                                    // ref={register({
                                                    // required: 'El campo Nombre es requerido.'
                                                    // })}
                                                />
                                                    {
                                                        stateNombresMensaje ? <span className="error-input">El campo Nombre es requerido.</span> : null
                                                    }
                                                {/* {                                                        
                                                    (errors.nombres && errors.nombres.message) ? <span className="error-input">{errors.nombres.message}</span> : null
                                                } */}
                                                    {/* <Input type="text"
                                                        id="nombres" name="nombres" 
                                                        defaultValue={stateForm.nombres}
                                                        // placeholder="Nombres"
                                                        onChange={inserInput}
                                                        ref={register({
                                                            required: 'Nombres requerido'
                                                        })}
                                                    />      */}
                                                    {/* {this.state.error.nombres ? <span className="error-input">Nombres</span> :  ""}                                        */}
                                                </Col>                                            
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                    {/* <h4>Apellidos:</h4> */}
                                                    <span className="titles-form">Apellido:</span>
                                                    <input type="text" className="form-control" id="apellidos" name="apellidos" value={stateForm.apellidos}
                                                        onChange={inserInput}
                                                        ref={register()}
                                                        // ref={register({
                                                        // required: 'El campo Apellidos es requerido.'
                                                        // })}
                                                    />   
                                                    {/* <span className="error-input">El campo Apellidos es requerido.</span>       */}
                                                    {
                                                        stateApellidosMensaje ? <span className="error-input">El campo Apellido es requerido.</span> : null
                                                    }
                                                </Col>                                            
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                {/* <h5 style={{fontWeight:"bold"}}>Rut:</h5> */}
                                                    <span className="titles-form">Rut:</span>
                                                    <input type="text" className="form-control" id="rut" name="rut"
                                                        value={stateForm.rut}
                                                        onChange={inserInput}
                                                        onBlur={OnblurValidateRut}
                                                        ref={register()}
                                                    />         
                                                    {
                                                        stateRutMensaje ? <span className="error-input">El campo Rut es requerido.</span> : null
                                                    }
                                                    {
                                                        stateRutMensajeV ? <span className="error-input">El campo Rut es inválido.</span> : null
                                                    }
                                                                                                        {
                                                        (stateRutMensajeR.estado && !stateRutMensajeV) ? <span className="error-input">{stateRutMensajeR.mensaje} </span> : null
                                                    }    
                                                    
                                                </Col>                                            
                                            </div>
                                        </fieldset>                                           

                                        <fieldset>
                                            <div>
                                                {/* <h5>Fecha de Nacimiento:</h5> */}
                                                <span className="titles-form">Fecha de Nacimiento:</span>
                                            </div>
                                            <div className="form-group row" style={{paddingTop:10}}>                                                   
                                                <Col md={ 4 }>
                                                    <span className="titles-form">Año</span>
                                                    <Select
                                                        name="year"
                                                        id="year"
                                                        placeholder="Seleccionar ..."
                                                        onChange={handleChangeSelectYears}
                                                        options={years}
                                                        value={{ label: stateForm.ano, value: stateForm.ano }}

                                                    />
                                                    {
                                                        stateAnoMensaje ? <span className="error-input">El campo Año es requerido.</span> : null
                                                    }
                                                </Col>
                                                <Col md={ 4 }>
                                                    <span className="titles-form">Mes</span>
                                                    <Select
                                                        name="month"
                                                        id="month"
                                                        placeholder="Seleccionar ..."
                                                        onChange={handleChangeSelectMonths}
                                                        options={MESES}
                                                        value={{ label: stateForm.mes_label, value: stateForm.mes }}
                                                    />
                                                    {
                                                        stateMesMensaje ? <span className="error-input">El campo Mes es requerido.</span> : null
                                                    }
                                                </Col>
                                                <Col md={ 4 }>
                                                    {/* <h5>&nbsp;</h5> */}
                                                    {/* <h5>Día</h5> */}
                                                    <span className="titles-form">Día</span>
                                                    <Select
                                                        name="day"
                                                        id="day"
                                                        placeholder="Seleccionar ..."
                                                        onChange={handleChangeSelectDays}
                                                        options={days}
                                                        value={{ label: stateForm.dia, value: stateForm.dia }}
                                                    />
                                                    {
                                                        stateDiaMensaje ? <span className="error-input">El campo Día es requerido.</span> : null
                                                    }
                                                </Col>
                                                <Col md={12}>
                                                    {/* {this.state.error.fecha_nacimiento ? <span className="error">{this.state.error.fecha_nacimiento}</span> :  ""} */}
                                                </Col>
                                            </div>
                                        </fieldset>

                                    </Fragment>  
                                    )
                                    : null
                                }                                 

                            </div>
                        </div>
                        
                        </div>
                        <div className="cardbox-footer text-center mb-4" style={{paddingTop:20}}>
                            <div>
                                {activeStep === steps.length ? (
                                <div>
                                    <h4>Muchas gracias por participar !</h4>
                                </div>
                                ) : (
                                <div>   
                                    <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                    <div>
                                    {/* <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Volver
                                    </Button> */}
                                    {/* {
                                    <Button variant="contained" className="button-color" type="submit">
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                                    </Button> 
                                    }  */}

                                    {/* ///////////////////////////////////////////////////////////////// */}                                    
                                    {
                                        (
                                            stateNombresValidation && stateApellidosValidation && stateRutValidation &&
                                            stateAnoValidation  && stateMesValidation && stateDiaValidation                                          

                                        ) 
                                        ? 
                                        (
                                            <Button variant="contained" className="button-color" type="submit">
                                            Finalizar
                                            </Button> 
                                        )
                                        :
                                        (
                                            <Button variant="contained" className="" type="submit" disabled>
                                            Finalizar
                                            </Button> 
                                        )                                        
                                    }


                                


                                    </div>
                                </div>
                                )}
                            </div>

                            {/* <button type="submit" className="btn btn-azul">Registrar</button> */}
                        </div>
                    </div>
                
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        // sitekey="6LeOLagZAAAAAOdRFLyFG0UCTQcAFWvYzZu6VSdr"
                        sitekey="6LctMVUaAAAAAKcE_Etj6msivmc29kQwg_2z3-VO"                        
                    />
                </form>
            </div>
        
        </div>
        )
        :
        tokenValid === false ?
        (
                <div className ="row">
                    <div className="col-xs-12 col-md-12" >                   
                            <img src="img/LogoComunidad.png" className="img-style rounded mx-auto d-block animated zoomInDown slow" alt="Logo Cadem Online" />
                    </div>
                </div>
        )
        : null
}
        </div>
        </BlockUi>
    </section>
    )
}
        
    
    // );


// }

export default FormularioComunidadCadem
