import React, {useEffect,useState, Fragment } from 'react';
import { useQueryParam, NumberParam, StringParam,ArrayParam } from 'use-query-params';
// import { Row, Col, Input } from 'reactstrap';
import { Col } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import Header from '../Header/Header';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

///////////////////////////////////////////////////////////////////////////////
// React Hook Form
import { useForm } from "react-hook-form";
///////////////////////////////////////////////////////////////////////////////

// Import CSS
import './formulario.css';
import { red } from '@material-ui/core/colors';

// Helpers
import Helpers from '../../helpers/index'; 
// import * as helpers from '../../helpers/helpers.js'; 
// const helpers = require('../../helpers/helpers')
///////////////////////////////////////////////////////////////////////////////
//Validar Rut
const { validate, clean, format } = require('rut.js')
const helpers = new Helpers();

// Configuraciónes
const config = require('../../configuration/config')
const API_BASE_URL = config.API_BASE_URL;





const MESES = [
    { value: 1, label: 'Enero' }, 
    { value: 2, label: 'Febrero' }, 
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' }
];

// const url_base = 'https://apidev.cadem.cl';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const styles = theme => ({
    icon: {
      color: "red !important"
    },
  });

// function formateaRut(rut) {

//     var actual = rut.replace(/^0+/, "");
//     if (actual != '' && actual.length > 1) {
//         var sinPuntos = actual.replace(/\./g, "");
//         var actualLimpio = sinPuntos.replace(/-/g, "");
//         var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
//         var rutPuntos = "";
//         var i = 0;
//         var j = 1;
//         for (i = inicio.length - 1; i >= 0; i--) {
//             var letra = inicio.charAt(i);
//             rutPuntos = letra + rutPuntos;
//             if (j % 3 == 0 && j <= inicio.length - 1) {
//                 rutPuntos = "." + rutPuntos;
//             }
//             j++;
//         }
//         var dv = actualLimpio.substring(actualLimpio.length - 1);
//         rutPuntos = rutPuntos + "-" + dv;
//     }
//     return rutPuntos;
// }

function getSteps() {
//   return ['Datos Personales', 'Actividad', 'Finalizar'];
// return ['Datos Personales', 'Actividad'];
return ['Datos Personales'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return '';
    case 1:
      return '';
    case 2:
      return '';
    default:
      return '';
  }
}

const FormularioComunidadCadem = () => {

 ///////////////////////////////////////////////////////////////////////////////
//State Form


// const [num, setNum] = useQueryParam('edad', NumberParam);

// const [token, setToken] = useState(useQueryParam('token', StringParam));
const [token, setToken] = useQueryParam('token', StringParam);
const [tokenValid, setTokenValid] = useState(true);


// console.log('edad', num);

// const [query, setQuery] = useQueryParam({
//     x: NumberParam,
//     q: StringParam,
//     filters: ArrayParam,
//   });

//   const { x: edad, q: searchQuery, filters = [] } = query;


const [stateCamposOcultos, setCamposOcultos] = useState(true);
const [stateNombresMensaje, setStateNombresMensaje] = useState(false);
const [stateApellidosMensaje, setStateApellidosMensaje] = useState(false);
const [stateRutMensaje, setStateRutMensaje] = useState(false);
const [stateRutMensajeV, setStateRutMensajeV] = useState(false);
const [stateRutMensajeR, setStateRutMensajeR] = useState({
    estado:false,
    mensaje:""
});

const [stateAnoMensaje, setStateAnoMensaje] = useState(false);
const [stateMesMensaje, setStateMesMensaje] = useState(false);
const [stateDiaMensaje, setStateDiaMensaje] = useState(false);

const [stateRegionMensaje, setStateRegionMensaje] = useState(false);
const [stateComunaMensaje, setStateComunaMensaje] = useState(false);

const [stateExtraData, setStateExtraData] = useState({});
const [stateForm, setStateForm] = React.useState({
    // id:"",
    // username:"",
    nombres:"",
    apellidos:"",
    rut:"",
    dv:"",
    fecha_nacimiento:"",
    ano:"Seleccionar ...",
    mes:"Seleccionar ...",
    dia:"Seleccionar ...",
    cod_ocupacion:"Seleccionar ...",
    ocupacion:"",
    cod_niveleducacional:"Seleccionar ...",
    niveleducacional:"",
    facebook:"",
    instagram:"",
    twitter:"",
    comunidad_cadem_id:""

    });
///////////////////////////////////////////////////////////////////////////////
const { handleSubmit, register, setValue, errors } = useForm();



const onSubmit = valuesForm => {
// const onSubmit = (valuesForm) => e => {      
    // console.log('ENTRO METODO onSubmit')
    // console.log('values',valuesForm);
    // console.log('stateForm', stateForm);
    // console.log('activeStep', activeStep )
    // console.log('steps.length - 1 ', steps.length - 1 )
    
    let resultValidacionDatosPersonales = validacionDatosPersonales(valuesForm);
    
    // console.log('resultValidacionDatosPersonales', resultValidacionDatosPersonales);
    
    if (resultValidacionDatosPersonales) {
        saveForm();
        // if(activeStep===0){handleNext()};
        // if(activeStep===1){saveForm()};
    }           
}


const validacionDatosPersonales = (valuesForm) =>{
    if(stateForm.nombres == ""){      
        setStateNombresMensaje(true);
      }else{
        setStateNombresMensaje(false);
      }

     if(stateForm.apellidos == ""){      
        setStateApellidosMensaje(true);
      }else{
        setStateApellidosMensaje(false);
      }

      if(stateForm.rut == "" ){      
        setStateRutMensaje(true);
      }else{  
            setStateRutMensaje(false);  
            // console.log('validate(valuesForm.rut)', validate(stateForm.rut));
            // console.log('valuesForm.rut.length', stateForm.rut.length);

            if(!validate(stateForm.rut) || stateForm.rut.length <=6){                
                setStateRutMensajeV(true);
            }else{
                setStateRutMensajeV(false);
            }
      }

      if(stateForm.ano == "Seleccionar ..."){      
        setStateAnoMensaje(true);
      }else{
        setStateAnoMensaje(false);
      }

      if(stateForm.mes == "Seleccionar ..."){      
        setStateMesMensaje(true);
      }else{
        setStateMesMensaje(false);
      }

      if(stateForm.dia == "Seleccionar ..."){      
        setStateDiaMensaje(true);
      }else{
        setStateDiaMensaje(false);
      }

      

    if(stateForm.nombres == "" || stateForm.apellidos == "" || stateForm.rut == "" 
    || stateForm.ano == "Seleccionar ..." || stateForm.mes == "Seleccionar ..." || stateForm.dia == "Seleccionar ..."
    ){
        return false;
    }else{
        return true;
    }

   

}
///////////////////////////////////////////////////////////////////////////////
const [OCUPACIONES, setOcupaciones] =  React.useState([]);
const [NIVELEDUCACIONAL, setNivelEducacional] =  React.useState([]);


const [comunas, setComunas] =  React.useState([]);
const [comuna_id, setComuna_id] =  React.useState('');
const [region_id,setRegion_id] =React.useState('0');
const [telefono_fijo,setTelefono_fijo,] =React.useState('');
const [years,setYears] =React.useState([]);
const [celular,setCelular] =React.useState('');
const [mes,setMes] =React.useState('');
const [days,setDays] =React.useState([]);
const [dia,setDia] =React.useState('');
const [fecha_nacimiento, setFecha_nacimiento] =React.useState('');
const [sostenedor,setSostenedor] =React.useState('');
const [email,setEmail] =React.useState('');
const [sid,setSid] =React.useState('');
const [id_lime,setId_lime] =React.useState('');
const [error,setError] =React.useState([]);
 

const classes = useStyles();
const [activeStep, setActiveStep] = React.useState(0);
const steps = getSteps();

  useEffect(() => {

    // if(token){
    //     validateToken();                   
    //     cargaYear();
    //     loadOcupaciones();
    //     loadNivelEducacional();
    // }else{
        
    //     setTokenValid(true);
    // }
                 
    cargaYear();
    loadOcupaciones();
    loadNivelEducacional();
        


},[]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


// Servicios Eventos
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


const validateRutIngresado = async() =>{
    // console.log('ENTRO ONBLUR validateRutIngresado');
    
    let datosformulario = {... stateForm};    
    let rutFormatiado = helpers.formateaRut(stateForm.rut);                
    // console.log('rutFormatiado', rutFormatiado);
    datosformulario.rut = rutFormatiado
    setStateForm(datosformulario);

    if(validate(stateForm.rut) && stateForm.rut.length > 6){
                        
        let rut = helpers.getRutSinDV(stateForm.rut);  
        let dv = helpers.getDV(stateForm.rut);    

        axios({
            url: API_BASE_URL + '/services-validate-rut',
            method: 'POST',
            data: {
            token: token,
            rut:rut,
            dv:dv
            }
            // responseType: 'blob',
            // headers: {
            //   'Access-Control-Allow-Origin': '*',
            //   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            //   'Authorization': token 
            // }
        },
        ).then((response) => {            
            if(response.data.ok){

                setStateRutMensajeR({estado:false,mensaje:""})

            }else{
                setStateRutMensajeR({
                    estado:false,
                    mensaje:""
                })
            }                        
        })
        .catch(error => {                                                              
                setStateRutMensajeR({
                    estado: true,
                    mensaje: error.response.data.mensaje
                })            
        });
    }else{
        console.log('RUT NO VALIDO');
        setStateRutMensajeR({estado:false,mensaje:""})
    }
}


const validateToken = async() =>{

    axios({
        url: API_BASE_URL + '/services-validate-token',
        method: 'POST',
        data: {
          token: token
        }
        // responseType: 'blob',
        // headers: {
        //   'Access-Control-Allow-Origin': '*',
        //   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        //   'Authorization': token 
        // }
      },
      ).then((response) => {
        // console.log('response.data', response.data);
        if(response.data.ok){
            setTokenValid(true);
            setStateExtraData(response.data.extradata)
        }else{
            setTokenValid(false);
        }                        
      })
      .catch(error => {                                                  
            // console.log('response.data', error.response.data);
            setTokenValid(false);
    });
}

const saveForm = async () => {

    // console.log('saveForm Guardando los datos');

    let datosformulario = {... stateForm};

    // let rut = datosformulario.rut.replace('-','').trim();    
    // rut = rut.replace('.','').replace('.','').replace('.','');
    // datosformulario.rut = rut.substring(0,rut.length - 1);
    // datosformulario.dv = rut.substring(rut.length - 1,rut.length);  
    let rut = helpers.getRutSinDV(datosformulario.rut); 
    let dv = helpers.getDV(datosformulario.rut); 
    datosformulario.rut = rut 
    datosformulario.dv = dv;  
    
    
    datosformulario.fecha_nacimiento = `${datosformulario.dia}/${datosformulario.mes}/${datosformulario.ano}`;
    datosformulario.cod_ocupacion = datosformulario.cod_ocupacion == "Seleccionar ..." ? "" : datosformulario.cod_ocupacion;
    datosformulario.cod_niveleducacional = datosformulario.cod_niveleducacional == "Seleccionar ..." ? "" : datosformulario.cod_niveleducacional;
    datosformulario.comunidad_cadem_id = token;
    // console.log('IMPORTANTE REVISAR DATOSFORMULARIO', datosformulario);
    
    handleNext()

    // axios({
    //     url: API_BASE_URL + '/services-save-form',
    //     method: 'POST',
    //     data: {
    //         datosformulario: datosformulario,
    //         extradata:stateExtraData
    //     }
    //     // responseType: 'blob',
    //     // headers: {
    //     //   'Access-Control-Allow-Origin': '*',
    //     //   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    //     //   'Authorization': token 
    //     // }
    //   },
    //   ).then((response) => {
    //     if(response.data.ok){
    //         handleNext()
    //     }                      
    //   })
    //   .catch(error => {                                                  
    //         console.log('response.data form', error.response.data);
            
    // });

}


const loadOcupaciones = async () => {

    axios.get(API_BASE_URL+'/services-ocupaciones')  
         .then(response => { 
            // console.log('response OCUPACIONES', response.data.ocupaciones)
            setOcupaciones(response.data.ocupaciones)

         })
         .catch(error => {
             console.log(error)
         });
}

const loadNivelEducacional = async () => {

    axios.get(API_BASE_URL+'/services-niveleducacion')  
         .then(response => { 
            // console.log('response NIVEL EDUCACIONAL', response.data.educacion)
            setNivelEducacional(response.data.educacion)
         })
         .catch(error => {
             console.log(error)
         });
}


// const cargaComunas = (id)=>{

//     axios.get(url_base + '/ApiCademPanel/api/getComunas/'+id).then(response => {  
//         setComunas(response.data)  
//    });
// }

const cargaYear = ()=>{
    // console.log('Cargar Años');
    // axios.get(url_base + '/ApiCademPanel/api/getYears').then(response => {  
    axios.get(API_BASE_URL + '/services-getyears').then(response => {  
        
        // console.log('response.data',response.data);
        // console.log('response.data.listYears',response.data.listYears);
        setYears(response.data.listYears);
        
   });
}

const cargaDaysFromMonth = (vmes)=>{

    if(vmes != "Seleccionar ..." && stateForm.ano != "Seleccionar ...")
        axios.get(`${API_BASE_URL}/services-getdaysofmonth/${stateForm.ano}/${vmes}`).then(response => {
            setDays(response.data.listDays)        
        });
}

const cargaDaysFromYear = (vyear)=>{

    if(vyear != "Seleccionar ..." && stateForm.mes != "Seleccionar ...")
        axios.get(`${API_BASE_URL}/services-getdaysofmonth/${vyear}/${stateForm.mes}`).then(response => {
            console.log('response.data',response.data);
            console.log('response.data.listYears',response.data.listDays);

            setDays(response.data.listDays)       
        });
}

// useEffect(() => {                
//      setRegion_id(selectedOptionMulti.value);
     
// },[]);



const inserInput = (e) => {
    const viene = e.target.name
    // console.log('viene', e.target.value)
    // console.log('e.target.name', viene)

    let newStateForm = {...stateForm};
    if(e.target.name === 'nombres'){
        newStateForm.nombres = e.target.value;    
        if(e.target.value == ""){setStateNombresMensaje(true)}else{setStateNombresMensaje(false)}
    }
    
    if(e.target.name === 'apellidos'){
        newStateForm.apellidos = e.target.value; 
        // console.log('newStateForm.apellidos', newStateForm.apellidos )            
        if(e.target.value == ""){setStateApellidosMensaje(true)}else{setStateApellidosMensaje(false)}
    }

    if(e.target.name === 'rut'){
        newStateForm.rut = e.target.value.trim(); 
        
        if(e.target.value == ""){setStateRutMensaje(true)}else{setStateRutMensaje(false)}

        if(!validate(e.target.value) || e.target.value.length <=6){
            setStateRutMensajeV(true);
        }else{
            setStateRutMensajeV(false);
        }       
    }

    if(e.target.name === 'facebook'){
        newStateForm.facebook = e.target.value; 
        // console.log('newStateForm.facebook', newStateForm.facebook )            
        // if(e.target.value == ""){setStateApellidosMensaje(true)}else{setStateApellidosMensaje(false)}
    }

    if(e.target.name === 'instagram'){
        newStateForm.instagram = e.target.value; 
        // console.log('newStateForm.facebook', newStateForm.instagram )            
        // if(e.target.value == ""){setStateApellidosMensaje(true)}else{setStateApellidosMensaje(false)}
    }

    if(e.target.name === 'twitter'){
        newStateForm.twitter = e.target.value; 
        // console.log('newStateForm.twitter', newStateForm.twitter )            
        // if(e.target.value == ""){setStateApellidosMensaje(true)}else{setStateApellidosMensaje(false)}
    }
       
    setStateForm(newStateForm); 
    // console.log('newStateForm inserInput', newStateForm);
}


const handleChangeSelectYears = (selectedOptionMulti) => {
    
    

    let newStateForm = {...stateForm};
    newStateForm.ano = selectedOptionMulti.value;      
    setStateForm(newStateForm); 

    if(selectedOptionMulti.value == "Seleccionar ..."){      
        setStateAnoMensaje(true);
      }else{
        setStateAnoMensaje(false);
      }

      cargaDaysFromYear(selectedOptionMulti.value);
}

const handleChangeSelectMonths= (selectedOptionMulti) => {

    setMes(selectedOptionMulti.value)
    let newStateForm = {...stateForm};
    newStateForm.mes = selectedOptionMulti.value;

    if(selectedOptionMulti.value == "Seleccionar ..."){      
        setStateMesMensaje(true);
      }else{
        setStateMesMensaje(false);
    }

    setStateForm(newStateForm); 

    cargaDaysFromMonth(selectedOptionMulti.value);

        //     if(ano != '')
    //     {
    //         cargaDays();
    //     }
    // this.setState({ mes:selectedOptionMulti.value},() => {
    //     if(ano != '')
    //     {
    //         cargaDays();
    //     }
    // });
   
}

const handleChangeSelectDays= (selectedOptionMulti) => { 
    let newStateForm = {...stateForm};
    newStateForm.dia = selectedOptionMulti.value;
    setStateForm(newStateForm); 

    if(selectedOptionMulti.value == "Seleccionar ..."){      
        setStateDiaMensaje(true);
      }else{
        setStateDiaMensaje(false);
    }
}

const handleChangeSelectOcupacion= (selectedOptionMulti) => { 
    let newStateForm = {...stateForm};

    // console.log('selectedOptionMulti.value',selectedOptionMulti.value);
    // console.log('selectedOptionMulti.text',selectedOptionMulti.label);
    
    newStateForm.cod_ocupacion = selectedOptionMulti.value;
    newStateForm.ocupacion = selectedOptionMulti.label;
    setStateForm(newStateForm); 

}

const handleChangeSelectNivelEducacional= (selectedOptionMulti) => { 
    let newStateForm = {...stateForm};

    // console.log('selectedOptionMulti.value',selectedOptionMulti.value);
    // console.log('selectedOptionMulti.text',selectedOptionMulti.label);

    newStateForm.cod_niveleducacional = selectedOptionMulti.value;
    newStateForm.niveleducacional = selectedOptionMulti.label;
    setStateForm(newStateForm); 
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    return (
        <section className="section-container mb-4">
            <div className="container">
            <Header></Header>
                <div className="row">
                    <div className="col-xs-12">&nbsp;</div>
                </div>


        
        {
        tokenValid ? (
                    
        <div className ="row">
            {/* <div className ="row animated bounceInRight delay-1s"> */}
            <div className="col-xs-6 col-md-6" >

                <div className ="row" style={{paddingLeft:34}}>
                    {/* <div className="col-md-3 col-xs-12">&nbsp;</div> */}
                    <div className="col-md-12 col-xs-12">&nbsp; 
                    {/* className="img-padding"                          */}
                            {/* <img src="img/LogoComunidad.png" className="rounded mx-auto d-block" alt="Logo Cadem Online" style={{width:200, height: 200 }}  /> */}
                            {/* <div> */}
                                {/* <img src="img/LogoComunidad.png" className="img-style" alt="Logo Cadem Online" style={{width:200, height: 200 }}  /> */}
                            {/* </div> */}
                            
                            <img src="img/LogoComunidad.png" className="img-style" alt="Logo Cadem Online" />
                            {/* <img src="img/LogoComunidad.png" className="rounded mx-auto d-block" alt="Logo Cadem Online" /> */}
                            
                    </div>
                    
                </div>
                <div className ="row" style={{ paddingTop:40, paddingLeft:40}}>
                    {/* <div className="col-md-3 col-xs-12">&nbsp;</div> */}
                    <div className="col-md-12 col-xs-12">
                        
                        <span  style={{fontSize: 32, fontWeight: "bold"}}>REGÍSTRATE</span>
                        <p className="registrate-style">
                            {/* Se parte de nuestra Comunidad Cadem <br></br>
                            y participa respondiendo encuestas */}
                            Se parte de nuestra Comunidad Cadem <br></br>
                            y participa de diversos premios respondiendo encuestas.
                        </p>
                        
                        
                    </div>
                    {/* <div className="col-md-3 col-xs-12">&nbsp;</div> */}
                </div>

                {/* <div className="cardbox">
                    <div className="cardbox-body">
                        <div className="container-img">
                            <img src="img/LogoComunidad.png" alt="Logo Cadem Online" />
                        </div>                             
                    </div>
                </div> */}
            </div>
            <div className="col-xs-6 col-md-6 fondo">                    
                {/* <form action="" name="actualizacion"> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="cardbox">
                        {/* <div className="cardbox-body" style={{paddingTop:25}}> */}
                        <div className="cardbox-body">
                            {/* <legend className="animated bounceIn faster"><h1 className="text-center font-weight-bold">Inscribete 2</h1></legend>
                            <hr />
                            <br></br> */}

                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active: classes.icon
                                        }
                                    }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                                ))}
                            </Stepper>


                            <div style={{paddingTop:20}}>                                    
                                {
                                    activeStep === 0 ? (

                                    <Fragment>
                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                {/* <h5>Nombres:</h5> */}
                                                <span className="titles-form">Nombre:</span>
                                                <input type="text" className="form-control" id="nombres" name="nombres" defaultValue={stateForm.nombres}
                                                    onChange={inserInput}
                                                    ref={register()}
                                                    // ref={register({
                                                    // required: 'El campo Nombre es requerido.'
                                                    // })}
                                                />
                                                    {
                                                        stateNombresMensaje ? <span className="error-input">El campo Nombre es requerido.</span> : null
                                                    }
                                                {/* {                                                        
                                                    (errors.nombres && errors.nombres.message) ? <span className="error-input">{errors.nombres.message}</span> : null
                                                } */}
                                                    {/* <Input type="text"
                                                        id="nombres" name="nombres" 
                                                        defaultValue={stateForm.nombres}
                                                        // placeholder="Nombres"
                                                        onChange={inserInput}
                                                        ref={register({
                                                            required: 'Nombres requerido'
                                                        })}
                                                    />      */}
                                                    {/* {this.state.error.nombres ? <span className="error-input">Nombres</span> :  ""}                                        */}
                                                </Col>                                            
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                    {/* <h4>Apellidos:</h4> */}
                                                    <span className="titles-form">Apellido:</span>
                                                    <input type="text" className="form-control" id="apellidos" name="apellidos" defaultValue={stateForm.apellidos}
                                                        onChange={inserInput}
                                                        ref={register()}
                                                        // ref={register({
                                                        // required: 'El campo Apellidos es requerido.'
                                                        // })}
                                                    />   
                                                    {/* <span className="error-input">El campo Apellidos es requerido.</span>       */}
                                                    {
                                                        stateApellidosMensaje ? <span className="error-input">El campo Apellido es requerido.</span> : null
                                                    }
                                                </Col>                                            
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                {/* <h5 style={{fontWeight:"bold"}}>Rut:</h5> */}
                                                    <span className="titles-form">Rut:</span>
                                                    <input type="text" className="form-control" id="rut" name="rut" 
                                                        // defaultValue={stateForm.rut}
                                                        value={stateForm.rut}
                                                        onChange={inserInput}
                                                        onBlur={validateRutIngresado}
                                                        ref={register()}
                                                    />         
                                                    {
                                                        stateRutMensaje ? <span className="error-input">El campo Rut es requerido.</span> : null
                                                    }
                                                    {
                                                        stateRutMensajeV ? <span className="error-input">El campo Rut es inválido.</span> : null
                                                    }
                                                                                                        {
                                                        (stateRutMensajeR.estado && !stateRutMensajeV) ? <span className="error-input">{stateRutMensajeR.mensaje} </span> : null
                                                    }    
                                                    
                                                </Col>                                            
                                            </div>
                                        </fieldset>                                           

                                        <fieldset>
                                            <div>
                                                {/* <h5>Fecha de Nacimiento:</h5> */}
                                                <span className="titles-form">Fecha de Nacimiento:</span>
                                            </div>
                                            <div className="form-group row" style={{paddingTop:10}}>                                                   
                                                <Col md={ 4 }>
                                                    {/* <div><p><h5>Fecha de Nacimiento</h5></p></div> */}
                                                    {/* <h5>&nbsp;</h5> */}
                                                    {/* <h5>Año</h5>                                                         */}
                                                    <span className="titles-form">Año</span>
                                                    <Select
                                                        name="year"
                                                        id="year"
                                                        placeholder="Seleccionar ..."
                                                        onChange={handleChangeSelectYears}
                                                        options={years}
                                                        defaultValue={{ label: stateForm.ano, value: stateForm.ano }}

                                                    />
                                                    {
                                                        stateAnoMensaje ? <span className="error-input">El campo Año es requerido.</span> : null
                                                    }
                                                </Col>
                                                <Col md={ 4 }>
                                                    {/* <h5>&nbsp;</h5> */}
                                                    {/* <h5>Mes</h5> */}
                                                    <span className="titles-form">Mes</span>
                                                    <Select
                                                        name="month"
                                                        id="month"
                                                        placeholder="Seleccionar ..."
                                                        onChange={handleChangeSelectMonths}
                                                        options={MESES}
                                                        defaultValue={{ label: stateForm.mes, value: stateForm.mes }}
                                                    />
                                                    {
                                                        stateMesMensaje ? <span className="error-input">El campo Mes es requerido.</span> : null
                                                    }
                                                </Col>
                                                <Col md={ 4 }>
                                                    {/* <h5>&nbsp;</h5> */}
                                                    {/* <h5>Día</h5> */}
                                                    <span className="titles-form">Día</span>
                                                    <Select
                                                        name="day"
                                                        id="day"
                                                        placeholder="Seleccionar ..."
                                                        onChange={handleChangeSelectDays}
                                                        options={days}
                                                        defaultValue={{ label: stateForm.dia, value: stateForm.dia }}
                                                    />
                                                    {
                                                        stateDiaMensaje ? <span className="error-input">El campo Día es requerido.</span> : null
                                                    }
                                                </Col>
                                                <Col md={12}>
                                                    {/* {this.state.error.fecha_nacimiento ? <span className="error">{this.state.error.fecha_nacimiento}</span> :  ""} */}
                                                </Col>
                                            </div>
                                        </fieldset>

                                    </Fragment>  
                                    )
                                    : 
                                    activeStep === 10 ? (
            
                                    <Fragment>

                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                    {/* <h5>Ocupación (Opcional):</h5> */}
                                                    {/* <span className="titles-form">Ocupación (Opcional):</span> */}
                                                        <span className="titles-form">Ocupación:</span>
                                                        <Tooltip title="Campo Opcional" placement="right-end">
                                                                <IconButton aria-label="help">
                                                                            <HelpIcon />
                                                                </IconButton>
                                                        </Tooltip>
                                                    <Select
                                                        id="ocupacion"
                                                        name="ocupacion"
                                                        placeholder="Seleccionar ..."
                                                        options={OCUPACIONES}
                                                        onChange={handleChangeSelectOcupacion}
                                                        defaultValue={{ label: stateForm.cod_ocupacion, value: stateForm.cod_ocupacion }}
                                                    />
                                                {/* {error.sostenedor ? <span className="error">{error.sostenedor}</span> :  ""} */}
                                                </Col>
                                            </div>
                                        </fieldset>

                                        <fieldset>
                                            <div className="form-group row">
                                                <Col md={ 12 }>
                                                    {/* <h5>Educación (Opcional):</h5> */}
                                                    
                                                    {/* <button type="button" className="btn btn-default" data-toggle="tooltip" data-placement="right" title="Tooltip on right">
                                                        <span style={{color:"red"}}>(?)</span>
                                                    </button> */}
                                                    {/* <Grid item> */}
                                                    <span className="titles-form">Educación :</span>
                                                        <Tooltip title="Campo Opcional" placement="right-end">
                                                                <IconButton aria-label="help">
                                                                            <HelpIcon />
                                                                </IconButton>
                                                        </Tooltip>
                                                    {/* </Grid> */}
                                                    
                                                    <Select
                                                        name="niveleducacion"
                                                        placeholder="Seleccionar ..."
                                                        onChange={handleChangeSelectNivelEducacional}
                                                        options={NIVELEDUCACIONAL}
                                                        defaultValue={{ label: stateForm.cod_niveleducacional, value: stateForm.cod_niveleducacional }}                                               
                                                    />

                                                </Col>
                                            </div>
                                        </fieldset>


                                       {!stateCamposOcultos ?
                                         <Fragment>

                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 12 }>
                                                        <span className="titles-form">Facebook:</span>
                                                            <Tooltip title="Campo Opcional" placement="right-end">
                                                                    <IconButton aria-label="help">
                                                                                <HelpIcon />
                                                                    </IconButton>
                                                            </Tooltip>
                                                        <input type="text" className="form-control" id="facebook" name="facebook" defaultValue={stateForm.facebook}
                                                            onChange={inserInput}
                                                            ref={register()}
                                                        />         
                                                    </Col>                                            
                                                </div>
                                            </fieldset>

                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 12 }>
                                                        <span className="titles-form">Instagram:</span>
                                                        <Tooltip title="Campo Opcional" placement="right-end">
                                                                    <IconButton aria-label="help">
                                                                                <HelpIcon />
                                                                    </IconButton>
                                                            </Tooltip>
                                                        <input type="text" className="form-control" id="instagram" name="instagram" defaultValue={stateForm.instagram}
                                                            onChange={inserInput}
                                                            ref={register()}
                                                        />         
                                                    </Col>                                            
                                                </div>
                                            </fieldset> 
                                        
                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 12 }>
                                                        <span className="titles-form">Twitter:</span>
                                                        <Tooltip title="Campo Opcional" placement="right-end">
                                                                    <IconButton aria-label="help">
                                                                                <HelpIcon />
                                                                    </IconButton>
                                                            </Tooltip>
                                                        <input type="text" className="form-control" id="twitter" name="twitter" defaultValue={stateForm.twitter}
                                                            onChange={inserInput}
                                                            ref={register()}
                                                        />         
                                                    </Col>                                            
                                                </div>
                                            </fieldset> 
                                        </Fragment>
                                        : null
                                        }


                                    </Fragment>


                                        )
                                        : null
                                        // (

                                        //     <div className="row">
                                        //         <div className="col-md-3"></div>
                                        //         <div className="col-xs-12 col-md-6 text-center mt-5">
                                        //             <h5>Muchas gracias por participar !</h5>
                                        //         </div>
                                        //         <div className="col-md-3"></div>
                                        //     </div>           
                                        // )
                                }                                 

                            </div>
                        </div>
                        
                        </div>
                        <div className="cardbox-footer text-center mb-4" style={{paddingTop:20}}>
                            <div>
                                {activeStep === steps.length ? (
                                <div>
                                    <h4>Muchas gracias por participar !</h4>
                                    {/* <h1 className="animated bounceInRight delay-1s">Muchas gracias por participar !</h1> */}
                                    {/* <Typography className={classes.instructions}>All steps completed</Typography>
                                    <Button onClick={handleReset}>Reset</Button> */}
                                </div>
                                ) : (
                                <div>
                                    <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                    <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Volver
                                    </Button>
                                    {/* <Button variant="contained" color="primary" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                                    </Button> */}
                                    {/* {activeStep === steps.length - 1 ?
                                    <Button variant="contained" color="primary" onClick={handleNext} type="submit">
                                        Finalizar
                                    </Button>
                                    :
                                    <Button variant="contained" color="primary" onClick={handleNext}>
                                        Continuar
                                    </Button>
                                    } */}
                                    {/* onClick={handleNext} */}
                                    {
                                        // <Button variant="contained" color="primary" type="submit">
                                        <Button variant="contained" className="button-color" type="submit">
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                                    </Button> 
                                    } 
                                


                                    </div>
                                </div>
                                )}
                            </div>

                            {/* <button type="submit" className="btn btn-azul">Registrar</button> */}
                        </div>
                    </div>
                </form>
            </div>
        
        </div>
        )
        :
        tokenValid === false ?
        (
                <div className ="row">
                    <div className="col-xs-12 col-md-12" >                   
                            <img src="img/LogoComunidad.png" className="img-style rounded mx-auto d-block animated zoomInDown slow" alt="Logo Cadem Online" />
                    </div>
                </div>
        )
        : null
}
        </div>
        </section>
    )
}
        
    
    // );


// }

export default FormularioComunidadCadem
